import { NextPage } from "next";
import Link from "next/link";
import React from "react";

import { ScreenWithCenteredContent } from "../main/components/ScreenWithCenteredContent";

const NotFoundPage: NextPage = () => {
    return (
        <ScreenWithCenteredContent>
            <div className="text-center">
                <div style={{ fontSize: "3rem" }}>404</div>
                <div className="text-secondary" style={{ fontSize: "2.5rem" }}>
                    Page not found
                </div>
                <div className="mt-2">
                    <Link href="/" passHref>
                        <a className="small">Go to the Homepage</a>
                    </Link>
                </div>
            </div>
        </ScreenWithCenteredContent>
    );
};

export default NotFoundPage;
