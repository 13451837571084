import { FC } from "react";

interface Props {
    children: React.ReactNode;
}

export const ScreenWithCenteredContent: FC<Props> = ({ children }) => {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "80vh" }}>
            <div className="mt-4" style={{ fontSize: "1.5rem" }}>
                {children}
            </div>
        </div>
    );
};
